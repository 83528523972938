export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const ADD_USER = "ADD_USER";

export const GET_ALL_ISTRUCTIONALS = "GET_ALL_ISTRUCTIONALS";
export const GET_INSTRUCTIONAL = "GET_INSTRUCTIONAL";
export const ADD_INSTRUCTIONAL = "ADD_INSTRUCTIONAL";
export const DELETE_INSTRUCTIONAL = "DELETE_INSTRUCTIONAL";
