import React from "react";

/** Context: provides currentUser object and setter for it throughout app. */

// currentUser 
// setCurrentUser
// login
// logout

const UserContext = React.createContext();

export default UserContext;
